import {
  AgentProfile,
  NestedAgentProfile,
  NestedBrokerage,
} from '../../@types/AgentProfile';
import {
  Brokerage,
  AgentProfileResponse,
  AgentProfile as AgentProfileJSONAPI,
} from '../../@types/JSONAPI/AgentProfile';

export function transpileAgentProfile(data: AgentProfileResponse) {
  const b = data.included.find(
    (item) =>
      item.type === 'brokerages' &&
      item.id === data.data.relationships.brokerage.data.id,
  ) as Brokerage | undefined;

  if (!b) {
    throw new Error('Brokerage not found');
  }

  const nestedBrokerage: NestedBrokerage = {
    ...b.attributes,
  };

  const a = data.included.find(
    (item) =>
      item.type === 'agent_profiles' &&
      item.id === data.data.relationships.brokerage.data.id,
  ) as AgentProfileJSONAPI | undefined;

  if (!a) {
    throw new Error('Agent Profile not found');
  }

  const nestedAgentProfile: NestedAgentProfile = {
    ...a.attributes,
  };

  const agentProfile: AgentProfile = {
    id: data.data.id,
    ...data.data.attributes,
    brokerage: nestedBrokerage,
    agent_profile: nestedAgentProfile,
  };

  return agentProfile;
}
